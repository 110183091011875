import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { FaHome, FaPhone, FaEnvelope } from "react-icons/fa";
import Iframe from "react-iframe";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


class ContactUs extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      
      first: "",
      //  last: "",
      help: "",
      telephone: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  handleInputChange(event) {
    event.preventDefault();
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({ [name]: value });
  }

  sendMessage = (event) => {
     
    event.preventDefault();
    if (this.state.email.length === 0 && this.state.telephone.length === 0) {
     // alert.show("phone or email required");
     toast.error("Phone or email required", {
      position: "top-center",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
      return;
    }
    const apiUrl = "https://birk.zendesk.com/api/v2/requests.json";
    const formData = {
      request: {
        requester: {
          // name: `${this.state.first} ${this.state.last}`,
          name: `${this.state.first}`,
          email: `${this.state.email}`,
        },
        subject: "Getting in touch",
        comment: {
          body: `${this.state.help}\r\nTelephone: ${this.state.telephone}\r\nEmail: ${this.state.email}`,
        },
      },
    };

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    };

    toast.success(
      "We have received your message and  We will get back to you soon",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );

    fetch(apiUrl, options, `Jrf7BjvmmAs4sSEGmyiHk9c5SCvtwuk3e6c5Sh1u`)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            email: this.state.email,
            first: this.state.first,
            //last: this.state.last,
            help: this.state.help,
            telephone: this.state.telephone,
          });
        },
        (error) => {
          this.setState({ error });
          toast.error("Something went wrong. Please try again", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      );
    this.setState({
      email: "",
      first: "",
      // last: "",
      help: "",
      telephone: "",
    });
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Architect and Designers | Melbourne | Brisbane | Birk</title>
          <meta
            property="og:title"
            content="Architect and Designers | Melbourne | Brisbane | Birk"
          />
          <meta
            name="keywords"
            content="Roller Blinds, Roman Blinds, Curtains, Shutters, Venetians, External Range, Automation, Honeycomb Blinds"
          />
          <meta
            name="description"
            content="Birk offers best of quality services with more than 30 year experience with architect and designers. We are operated in Melbourne and Brisbane"
          />
          <meta
            property="og:description"
            content="Birk offers best of quality services with more than 30 year experience with architect and designers. We are operated in Melbourne and Brisbane"
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content="http://birk.itechnotion.com/architect-designer"
          />
          <link
            rel="canonical"
            href="http://birk.itechnotion.com/architect-designer"
          />
        </Helmet>
        <Header />
        <ToastContainer />
        <section className="contact-section">
          <div className="container-fluid all-section">
            <div className="row">
              <div className="heading">
                <h2>Contact Us</h2>
              </div>
            </div>
            <div className="row justify-content-center align-items-center">
              <div className="col-sm-12 col-md-6 col-lg-8">
                <div className="about-form">
                  <form onSubmit={this.sendMessage}>
                    <div className="form-group">
                      <label htmlFor="about-First" className="font-weight-bold">
                        Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="about-First"
                        name="first"
                        value={this.state.first}
                        onChange={this.handleInputChange.bind(this)}
                        required
                      />
                    </div>
                    {/* <div className="form-group">
                      <label htmlFor="about-Last" className="font-weight-bold">
                        Last Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="about-Last"
                        name="last"
                        value={this.state.last}
                        onChange={this.handleInputChange.bind(this)}
                        required
                      />
                    </div> */}
                    <div className="form-group">
                      <label htmlFor="about-Phone" className="font-weight-bold">
                        Phone Number
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="about-Phone"
                        name="telephone"
                        value={this.state.telephone}
                        onChange={this.handleInputChange.bind(this)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="about-Email" className="font-weight-bold">
                        Email
                        {/* <span className="text-danger">*</span> */}
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="about-Email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleInputChange.bind(this)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="about-Help" className="font-weight-bold">
                        How Can We Help?<span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        id="about-Help"
                        rows="5"
                        name="help"
                        value={this.state.help}
                        onChange={this.handleInputChange.bind(this)}
                        required
                      ></textarea>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-dark text-uppercase"
                    >
                      submit
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <div className="address-box mb-3 mt-2">
                  <h4 className="pb-2">Melbourne</h4>
                  <ul className="list-unstyled">
                    <li className="d-flex align-item-center">
                      <span className="foo_cir mr-2">
                        <FaHome />
                      </span>
                      2/55 Northgate Drive Thomastown, VIC, 3074 Australia
                    </li>
                    <li>
                      <a href="tel:1300 664 991">
                        <span className="foo_cir mr-2">
                          <FaPhone />
                        </span>
                        1300 664 991
                      </a>
                    </li>
                    <li>
                      <a href="mailto:sales@birk.com.au">
                        <span className="foo_cir mr-2">
                          <FaEnvelope />
                        </span>
                        sales@birk.com.au
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="address-box">
                  <h4 className="pb-2">Brisbane</h4>
                  <ul className="list-unstyled">
                    <li className="d-flex align-item-center">
                      <span className="foo_cir mr-2">
                        <FaHome />
                      </span>
                      Cannon Hill, QLD, 4170 Australia
                    </li>
                    <li>
                      <a href="tel:1300 664 991">
                        <span className="foo_cir mr-2">
                          <FaPhone />
                        </span>
                        1300 664 991
                      </a>
                    </li>
                    <li>
                      <a href="mailto:sales@birk.com.au">
                        <span className="foo_cir mr-2">
                          <FaEnvelope />
                        </span>
                        sales@birk.com.au
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="map-section">
          <div className="container-fluid all-section">
            <div className="row">
              <Iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3157.2946782361196!2d145.03653831531514!3d-37.68927797977518!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad64f480f23e6d5%3A0xb6387f17bb7114!2sUnit%202%2F55%20Northgate%20Dr%2C%20Thomastown%20VIC%203074%2C%20Australia!5e0!3m2!1sen!2sin!4v1618393943478!5m2!1sen!2sin"
                width="100%"
                height="600"
                style={{ border: "0" }}
                allowfullscreen
                loading="lazy"
              ></Iframe>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}
export default ContactUs;
